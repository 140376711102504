import React, {useCallback} from "react";
import styles from "./dropzoneFullScreen.module.scss";
import {useDropzone} from "react-dropzone";
import {IFileFromInput} from "../../ducks/upload";

export interface IDropzoneFullScreenProps {
    files: IFileFromInput[];
    setFiles: (files: IFileFromInput[]) => void;
    onDropHandler: (params: any) => void;
    onDragStart: VoidFunction;
    onDragEnd: VoidFunction;
}

export default function DropzoneFullScreen(props: IDropzoneFullScreenProps) {
    const onDrop = useCallback(props.onDropHandler, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})
    return (
        <div
            className={styles.popupBack}
        >
            <div {...getRootProps({
                accept: {
                    'application/msword': ['.docx', '.doc'],
                    'application/docx': [],
                    'application/pdf': ['.pdf', '.PDF'],
                    'text/rtf': ['.rtf'],
                    'image/*': []
                }
            })}
                 onDragLeave={() => {
                     setTimeout(() => {
                         props.onDragEnd();
                     }, 0)
                 }} className={styles.dropzoneContainerFull}/>
            <div
                onDragEnter={() => {
                    props.onDragStart();
                }}
                className={styles.popupContainer}
            >
                <div className={styles.dropZone}>
                    <input
                        {...getInputProps({
                            accept: "image/jpeg,image/png,application/pdf,image/tiff,application/msword,text/rtf"
                        })}
                    />
                    <div className={styles.dropZoneTitle}>
                        ПЕРЕМЕСТИТЕ ФАЙЛЫ СЮДА
                    </div>
                </div>
            </div>
        </div>
    )
}

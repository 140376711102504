import {useEffect} from "react";
import {getBoolFromLocalStorage, vmAuthToken} from "../helper/other";
import {getStoreUtils} from "../store/utils";
import {push} from "connected-react-router";
import {ROUTES} from "../app/Routes";

export const useVmAuth  = () => {

    useEffect(() => {
        const authValue = getBoolFromLocalStorage(vmAuthToken);
        //@ts-ignore
        if(window.REACT_APP_VM_AUTH && !authValue) {
            getStoreUtils().dispatch(push(ROUTES.Authorization));
        }

    }, []);
}

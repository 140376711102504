import {CSSProperties} from "react";
import {styled} from "@mui/material/styles";
import {TablePagination} from '@material-ui/core';

export const smallCellSx: CSSProperties = {width: '5%', padding: 0, alignSelf: 'center', alignItems: 'center'};
export const headerSmallSx:  CSSProperties = {
    textAlign: 'left',
    alignItems: 'left !important',
    zIndex: 0,
    backgroundColor: '#FFF',
    fontFamily: "'Lato', sans-serif",
    fontSize: '12px',
    fontWeight: '400 !important',
    lineHeight: '14px',
    letterSpacing: '0em',

};
export const headerNormalSx: CSSProperties = {
    zIndex: 0,
    backgroundColor: '#FFF',
    fontFamily: "'Lato', sans-serif",
    fontSize: '12px',
    fontWeight: '400 !important',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'left',
    width: "auto !important",
};

export const defaultRowStyle: CSSProperties = {
    zIndex: '0 !important',
    fontFamily: "'Lato', sans-serif",
    fontSize: '12px',
    fontWeight: '300 !important',
    lineHeight: '20px',
    letterSpacing: '0em',
}

export const rowHeight = 31;
export const paginationHeightWithPadding = 80;
export const headerHeight = 34.5;

export const StyledTablePagination = styled(TablePagination)`
  .MuiTablePagination-toolbar {
  }
`;

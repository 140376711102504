import {IconButton, TextField} from "@mui/material";
import {setDifferenceLinkPopup} from "../../ducks/montitoring";

import styles from "./DifferenceLink.module.scss";
import React, {FC, useEffect} from "react";
import {useSelector} from "react-redux";
import {selectDifferenceLink} from "../../selectors/monitoring";
import {getStoreUtils} from "../../store/utils";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {ROUTES} from "../../app/Routes";

export const DifferenceLink: FC = () => {
    const {dispatch} = getStoreUtils();
    const popupState = useSelector(selectDifferenceLink);
    const isOpen = !!popupState;
    const link = `${window.location.origin}${process.env.REACT_APP_ROUTE_PREFIX ?? ''}${ROUTES.DocPublic}/${popupState?.hash}`;

    const onCopy = () => {
        navigator?.clipboard?.writeText(link);
    };

    useEffect(() => {
        if(isOpen && popupState?.hash) {
            onCopy();
        }
    }, [isOpen, popupState]);
    const onClose = () => {
        dispatch(setDifferenceLinkPopup(undefined));
    };

    return isOpen? (
        <div
            onClick={onClose}
            className={styles.popupBack}>
            <div
                onClick={(e)=>{
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
                className={styles.popupContainer}
            >
                <div className={styles.messageContainer}>
                    <div className={styles.row}>
                        <span className={styles.message}>
                            Вы можете поделиться ссылкой с другими пользователями.
                       </span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.message}>
                            Срок действия ссылки - 1 неделя
                       </span>
                    </div>
                    <div className={styles.row}>
                        <TextField
                            value={link}
                            fullWidth
                            size="small"
                            InputLabelProps={{shrink: true}}
                            sx={{fontSize: '12px'}}
                            margin={"normal"}
                        />
                        <IconButton
                            onClick={onCopy}
                        >
                            <ContentCopyIcon/>
                        </IconButton>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.copyMessage}>
                            Ссылка скопирована
                       </span>
                    </div>
                    <IconButton
                        onClick={onClose}
                        className={styles.closeIcon}
                    >
                    </IconButton>
                </div>

            </div>
        </div>
    ) : (
        <></>
    );
}

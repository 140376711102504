import React, {CSSProperties} from 'react';
import MaterialTable, {Column, MTableBodyRow} from "material-table";
import {Paper, ThemeProvider} from "@mui/material";
import {materialTableLocalizationRu} from "./materialTableLocalization";
import {TablePagination} from '@material-ui/core';
import {tableIcons} from "./tableIcons";
import styles from "./Table.module.scss"
import {IRecognitionActTable} from "../../pages/MonitoringPage/MonitoringPage";
import {theme} from "../../theme/theme";
import {getDiscrepancyRatio} from "../../helper/other";
import {
    defaultRowStyle,
    headerHeight,
    headerNormalSx,
    headerSmallSx,
    paginationHeightWithPadding,
    rowHeight,
    smallCellSx, StyledTablePagination
} from "./consts";

export type TableProps = {
    pageSize: number;
    initialPage?: number;
    columns: Column<Object>[];
    onChangeListPage?: (pageNumber: number) => void;
    data?: Object[];
    onRowClick?: (event: any, rowData: any) => void;
    onContextMenu?: (event: any, rowData: any) => void;
    selection?: boolean;
    onSelectRow?: (rows: IRecognitionActTable[], rowData?: IRecognitionActTable) => void;
    selectedIds?: number[];
    bodyHeight?: number;
    onOrderChange?: (orderBy: number, orderDirection: "asc" | "desc") => void;
    orderBy?: number;
    orderDirection?: "asc" | "desc";
}

const getDataBySort = ({data, orderBy, orderDirection, columns }: Pick<TableProps, 'data' | 'orderBy' | 'orderDirection' | 'columns'> ) => {
    if(!data || !data.length || !columns || !columns.length || !orderDirection || orderBy < 0) return data;
    const sortFunction = columns[orderBy]?.customSort;
    if(!sortFunction) return data;
    //@ts-ignore
    if(columns[orderBy]?.field === 'discrepancyRatio') {
        const numberData = data.filter((el: IRecognitionActTable) => el.discrepancyRatio !== null);
        const nullData = data.filter((el: IRecognitionActTable) => el.discrepancyRatio === null);
        return [...numberData.sort((a: IRecognitionActTable, b: IRecognitionActTable) => {
            return (orderDirection === 'asc') ? getDiscrepancyRatio(b.discrepancyRatio) - getDiscrepancyRatio(a.discrepancyRatio) : getDiscrepancyRatio(a.discrepancyRatio) - getDiscrepancyRatio(b.discrepancyRatio);
        }), ...nullData].map((el: IRecognitionActTable) => ({...el, isSorted: true}))
    }
    return structuredClone(data.sort((a: IRecognitionActTable, b: IRecognitionActTable) => orderDirection === 'asc' ? sortFunction(a,b, 'row') : sortFunction(b,a, 'row')).map((el: IRecognitionActTable) => ({...el, isSorted: true})))
}

export function Table(props: TableProps) {
    const {columns, initialPage, bodyHeight, pageSize, data, onRowClick, selection, onSelectRow, selectedIds, onContextMenu, orderBy, onOrderChange, orderDirection} = props;
    const prepareColumns: Column<Object>[] = columns.map((col, index) => {
        if(["status", 'discrepancyRatio'].includes(col.field)) {
            return  {
                ...col,
                cellStyle: smallCellSx,
                headerStyle: headerSmallSx
            };
        }
        if(["upload_date",].includes(col.field)) {
            return  {
                ...col,
                cellStyle: {...smallCellSx, width: '11%'},
                headerStyle: headerSmallSx
            };
        }

        return {
            ...col,
            cellStyle:{width: '25%'},
            headerStyle: {...headerNormalSx},
        };
    });
    const changePage = e => {
        if (props.onChangeListPage) {
            props.onChangeListPage(e)
        }
    }
    const updateMaterialTableKey = `updateMaterialTableKey-${Math.random()}`;
    const rowsCount = bodyHeight ?  Math.floor((bodyHeight - headerHeight - paginationHeightWithPadding)/rowHeight) : pageSize;

    return <div className={styles.tableContainer}>
        <ThemeProvider theme={theme}>
        <MaterialTable
            key={updateMaterialTableKey}
            components={{
                Container: props => <Paper {...props} elevation={0}/>,
                Row: props =>(<MTableBodyRow
                    {...props}
                    onContextMenu={(e) => onContextMenu(e, props.data)}
                />),
                Pagination: props => <StyledTablePagination {...props} />,
            }}
            columns={prepareColumns.map((column, index) => {
                return ({...column, defaultSort: (orderBy === index) ? orderDirection : undefined})
            })}
            data={getDataBySort({data, orderBy, orderDirection, columns})}
            localization={materialTableLocalizationRu}
            icons={tableIcons}
            onRowClick={onRowClick}
            options={{
                rowStyle: defaultRowStyle,
                initialPage: initialPage,
                pageSizeOptions: [],
                selection,
                pageSize: rowsCount > 0 ? rowsCount : pageSize,
                actionsColumnIndex: 7,
                sorting: true,
                emptyRowsWhenPaging: true,
                draggable: false,
                search: false,
                searchFieldAlignment: "left",
                showTitle: false,
                toolbar: false,
                thirdSortClick: false,
                selectionProps: (rowData: IRecognitionActTable) => ({
                    checked: selectedIds?.includes(rowData?.id),
                }),
                headerSelectionProps: {
                    cellStyle: {...smallCellSx, width: '4%'},
                    headerStyle: headerSmallSx,
                    checked: (selectedIds.length === data.length) && data.length > 0
                },
                minBodyHeight: bodyHeight ? bodyHeight - paginationHeightWithPadding : undefined,

                maxBodyHeight: bodyHeight ? bodyHeight - paginationHeightWithPadding : undefined,
            }}
            onSelectionChange={(data: IRecognitionActTable[], rowData: IRecognitionActTable) => {
                onSelectRow(data,rowData);
            }}
            onOrderChange={onOrderChange}
            onChangePage={changePage}
            actions={[
                {
                    tooltip: 'Удалить',
                    icon: 'delete',
                    onClick: (evt, data) => alert('You want to delete ' + selectedIds.length + ' rows')
                }
            ]}
        />
        </ThemeProvider>
    </div>
}

import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../store/rootReducers";
import {DifferencePage, FilesData, IDifferencePageDispatchProps, IDifferencePageStateProps} from "./DifferencePage";
import {
    selectDetailedDiff,
    selectDetailedDocIdLeft,
    selectDetailedPair,
    selectDifferenceBarPosition,
    selectDifferenceData,
    selectDocFilterList,
    selectFirstDocumentPosition,
    selectIsChangingDetailedDiff,
    selectIsChangingView,
    selectIsPageLoading,
    selectLeftImages,
    selectLeftScale,
    selectMagnifierAdded,
    selectMenuOpen,
    selectRightImages,
    selectRightScale,
    selectScrollSync,
    selectZoomCount,
} from "../../selectors/monitoring";
import {
    deleteUnrecognizedPackagesImages,
    onClearDifferencePage,
    setDetailedDiff,
    setDifferenceStatus,
    setIsChangingDetailedDiff,
    setIsTrackDisabled,
    setLeftScale,
    setMenuOpen,
    setRightScale,
} from "../../ducks/montitoring";
import {selectUrlId} from "../../selectors/other";
import {selectComment, selectTechReportText} from "../../selectors/upload";

const mapStateToProps: MapStateToProps<IDifferencePageStateProps, {}, State> = state => {
    const detailedDiff = selectDetailedDiff(state);
    const panelPosition = selectDifferenceBarPosition(state);
    const docPosition = selectFirstDocumentPosition(state);
    const menuOpen = selectMenuOpen(state);
    const urlId = selectUrlId(state);
    const techData = selectDetailedPair(state)?.techData;
    const detailedPair = selectDetailedPair(state);
    const filesData: FilesData = detailedPair?.files?.sort((a,b) => (a?.place - b?.place))?.reduce((previousValue, currentValue, currentIndex) => {
        switch (currentIndex) {
            case 0: {
                return {...previousValue, firstDocumentName: currentValue?.name}
            }
            case 1: {
                return {...previousValue, secondDocumentName: currentValue?.name}
            }
        }
        return previousValue;
    }, {
        firstDocumentName: '',
        secondDocumentName: '',
    });
    const differenceData = selectDifferenceData(state);
    const docFilterList = selectDocFilterList(state);
    const filteredDiscrepancyIndexes: number[] = differenceData?.map((el, index) => ({...el, originalIndex: index})).filter((el) => el.discrepancyElements && el.discrepancyElements.length > 0 && el.discrepancyElements.filter(discrepancy => docFilterList.includes(discrepancy)).length === 0).map(el => el.originalIndex);


    return {
        barWidth: menuOpen ? 300 : 80,
        urlId,
        docFilterList: selectDocFilterList(state),
        isPageLoading: selectIsPageLoading(state),
        isChangingView: selectIsChangingView(state),
        isChangingDetAiledDiff: selectIsChangingDetailedDiff(state),
        leftScale: selectLeftScale(state),
        rightScale: selectRightScale(state),
        differenceData: differenceData.sort((a,b) => a.indexFromApi - b.indexFromApi),
        detailedDiff,
        imagesLeft: selectLeftImages(state),
        imagesRight: selectRightImages(state),
        scrollSync: selectScrollSync(state),
        menuOpen: menuOpen,
        panelPosition,
        docPosition,
        zoomCount: selectZoomCount(state),
        isMagnifierActive: selectMagnifierAdded(state),
        techData,
        report: selectTechReportText(state),
        detailedDocLeftId: selectDetailedDocIdLeft(state),
        comment: selectComment(state),
        filteredDiscrepancyIndexes,
        filesData,
    }
};

const dispatchProps: MapDispatchToProps<IDifferencePageDispatchProps, {}> = {
    setDetailedDiff,
    setDifferenceStatus,
    onClearDifferencePage,
    setLeftScale,
    setRightScale,
    setIsTrackDisabled,
    deleteUnrecognizedPackagesImages,
    setIsChangingDetailedDiff,
    setMenuOpen,
};

export const DifferencePageConnected = connect(
    mapStateToProps,
    dispatchProps,
)(DifferencePage);

import {State} from "../store/rootReducers";

export function selectMonitoringList(state: State) {
    return state.monitoring.list;
}

export function selectDetailedDiff(state: State) {
    return state.monitoring.selectedDiff;
}

export function selectCommentInputShown(state: State) {
    return state.monitoring.isCommentInputShown;
}

export function selectRightImages(state: State) {
    return state.monitoring.rightDetailedImages;
}

export function selectMenuOpen(state: State) {
    return state.monitoring.menuOpen;
}

export function selectLeftImages(state: State) {
    return state.monitoring.leftDetailedImages;
}

export function selectLeftScale(state: State) {
    return state.monitoring.leftScale;
}

export function selectIsChangingView(state: State) {
    return state.monitoring.isChangingView;
}

export function selectRightScale(state: State) {
    return state.monitoring.rightScale;
}

export function selectIsTrackDisabled(state: State) {
    return state.monitoring.isTrackDisabled;
}

export function selectDetailedDocIdLeft(state: State) {
    return state.monitoring.detailedDocumentIdLeft;
}

export function selectSearchOptions(state: State) {
    return state.monitoring.searchOptions;
}

export function selectDifferenceData(state: State) {
    const data = state.monitoring.differenceData;
    return data.map((el,index) => {
        if(index === data.length - 1) return ({...el, discrepancyElements: ['Stamp']});
        return el;
    });
}

export function selectDifferenceBarPosition(state: State) {
    return state.monitoring.panelPosition;
}

export function selectFirstDocumentPosition(state: State) {
    return state.monitoring.docPosition;
}

export function selectIsChangingDetailedDiff(state: State) {
    return state.monitoring.isChangingDetailedDiff;
}

export function selectListPage(state: State) {
    return state.monitoring.listPage;
}
export function selectDocFilterList(state: State) {
    return state.monitoring.docFilterList;
}

export function selectSortType(state: State) {
    return state.monitoring.sortType;
}

export function selectOrderBy(state: State) {
    return state.monitoring.sortOrderBy;
}

export function selectDetailedPair(state: State) {
    return state.monitoring.detailedPair;
}

export function selectScrollSync(state: State) {
    return state.monitoring.scrollSync;
}

export function selectPairId(state: State) {
    return state.monitoring.pairId;
}

export function selectIsPageLoading(state: State) {
    return state.monitoring.pageLoadingState;
}

export function selectZoomCount(state: State) {
    return state.monitoring.zoomCount;
}

export function selectMagnifierAdded(state: State) {
    return state.monitoring.isMagnifierActive;
}
export function selectSelectedRowIds(state: State) {
    return state.monitoring.selectedRowIds;
}
export function selectDifferenceComment(state: State) {
    return state.monitoring.differenceComment;
}
export function selectDifferenceLink(state: State) {
    return state.monitoring.differenceLink;
}

export function setStorageSortType(sortType: string | undefined) {
    window.localStorage.setItem("sort", sortType);
}

export function setStorageOrderBy(orderBy: number | undefined) {
    window.localStorage.setItem("orderBy", orderBy?.toString() ?? '');
}

export function getSortType(): "asc" | "desc" | undefined {
    let item;
    const storageItem = window.localStorage.getItem("sort");
    try {
        item = storageItem;
    } catch {
        item = undefined;
    }
    return item ? item : undefined;
}
export function getStorageOrderBy(): number {
    let item;
    const storageItem = window.localStorage.getItem("orderBy");
    try {
        item = storageItem;
    } catch {
        item = undefined;
    }
    const result = Number(item);
    return ((result || result === 0) && !isNaN(result) ) ? result : 2;
}

export function setStoragePage(page: number) {
    window.localStorage.setItem("page", `${page}`);
}

export function getStoragePage() {
    let item;
    const storageItem = window.localStorage.getItem("page");
    try {
        item = parseInt(storageItem);
    } catch {
        item = 0;
    }
    return item ? item : 0;
}

import React, {FC, useEffect} from "react";
import styles from './UploadPopup.module.scss';
import MyDropzone from "../dropzone/dropzone";
import {
    IDetailedDocumentPage,
    IDetailedFile,
    IFileFromInput,
    PairUploading,
    setUploadPopupBackground,
} from "../../ducks/upload";
import {PopupElement} from "../../ducks/popup";
import {asyncEncodeImageFileAsURL} from "../../helper/other";
import {Button, IconButton} from "@mui/material";
import {PairDocs} from "../../api/uploadApi";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import {getStoreUtils} from "../../store/utils";
import DropzoneFullScreen from "../dropzone/dropzoneFullScreen";

export interface IUploadPopupStateProps {
    firstDocumentFiles: IFileFromInput[];
    secondDocumentFiles: IFileFromInput[];
    detailedFile: IDetailedFile | undefined;
    pageCount: number;
    isLoading: boolean;
    showPreviewPages: boolean;
    selectedIds: string[];
    page: IDetailedDocumentPage | undefined;
    isOpen: boolean;
    firstDocumentLoading: boolean;
    secondDocumentLoading: boolean;
    isBackground: boolean;
}

export interface IUploadPopupDispatchProps {
    setUploadPageCount: (count: number) => void;
    setSelectedIds: (ids: string[]) => void;
    setDetailedFilePages: (page: IDetailedDocumentPage | undefined) => void;
    setUploadIsLoading: (isLoading: boolean) => void;
    setDetailedUpload: (uploadFile: IDetailedFile | undefined) => void;
    setUploads: (uploads: IFileFromInput[], usePrevUploads?: boolean, pairDoc?: PairDocs) => void;
    deleteUploads: (uploads: IFileFromInput[]) => void;
    uploadFiles: (uploads: IFileFromInput[], pairDoc: PairDocs) => void;
    uploadAndRecognizePair: (props: PairUploading) => void;
    popupPush: (popup: PopupElement) => void;
    setShowPreviewPages: (value: boolean) => void;
    setUploadPopupOpen: (value: boolean) => void;
    sendPackageToRecognition: (redirect?: boolean) => void;
    onClearUploads: () => void;
    getUploads: () => void;
}

export type UploadPopupProps = IUploadPopupStateProps & IUploadPopupDispatchProps;

export const UploadPopup:FC = (props: UploadPopupProps) => {
    const handleFileDropFirst = async (acceptedFiles) => {
        if (acceptedFiles.length === 0) {
            props.popupPush(
                {
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    data: ["Данный формат файлов не поддерживается"],
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                })
        } else {
            let newFiles = [];
            for (let i = 0; i < acceptedFiles.length; i++) {
                const file = acceptedFiles[i];
                await asyncEncodeImageFileAsURL(file)
                    .then(async (base64res: any) => {
                        newFiles.push({
                            inputIndex: i,
                            id: `${new Date().getTime()}${i}`,
                            fileUrl: base64res,
                            fileName: file.name,
                            fileSize: file.size,
                            mimeType: file.type,
                        })
                    });
            }
            const sendFiles = [...newFiles].filter(elem => {
                const extension = elem.fileName.split('.').pop();
                if (extension) {
                    return ['pdf', 'PDF', 'docx', 'png', 'tiff', 'jpg', 'rtf', 'doc'].includes(extension.toLowerCase())
                } else {
                    return false;
                }
            })
            if (sendFiles.length < newFiles.length) {
                const unsupportedFiles = newFiles.filter(file => !sendFiles.includes(file)).map(file => file.fileName)
                const unsupportedFilesNames = unsupportedFiles.join(', ')
                await props.popupPush(
                    {
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: [`${unsupportedFilesNames}: неподдерживаемый формат`],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    })
            }
            if (sendFiles.length > 0) {
                props.uploadFiles(sendFiles, PairDocs.First)
            }
        }
    }
    const handleFileDropSecond = async (acceptedFiles) => {
        if (acceptedFiles.length === 0) {
            props.popupPush(
                {
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    data: ["Данный формат файлов не поддерживается"],
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                })
        } else {
            let newFiles = [];
            for (let i = 0; i < acceptedFiles.length; i++) {
                const file = acceptedFiles[i];
                await asyncEncodeImageFileAsURL(file)
                    .then(async (base64res: any) => {
                        newFiles.push({
                            inputIndex: i,
                            id: `${new Date().getTime()}${i}`,
                            fileUrl: base64res,
                            fileName: file.name,
                            fileSize: file.size,
                            mimeType: file.type,
                        })
                    });
            }
            const sendFiles = [...newFiles].filter(elem => {
                const extension = elem.fileName.split('.').pop();
                if (extension) {
                    return ['pdf', 'PDF', 'docx', 'png', 'tiff', 'jpg', 'rtf', 'doc'].includes(extension.toLowerCase())
                } else {
                    return false;
                }
            });
            if (sendFiles.length < newFiles.length) {
                const unsupportedFiles = newFiles.filter(file => !sendFiles.includes(file)).map(file => file.fileName)
                const unsupportedFilesNames = unsupportedFiles.join(', ')
                await props.popupPush(
                    {
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: [`${unsupportedFilesNames}: неподдерживаемый формат`],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    })
            }
            if (sendFiles.length > 0) {
                props.uploadFiles(sendFiles, PairDocs.Second);
            }
        }
    }
    const handleFileDropBoth = async (acceptedFiles) => {
        await getStoreUtils().dispatch(setUploadPopupBackground(false))
        if (acceptedFiles.length < 2) {
            props.popupPush(
                {
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    data: ["Для отправки пары на сравнение загрузите измененный документ"],
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                })
        } else {
            const acceptedFilesFirst = [structuredClone(acceptedFiles)[0]]
            let newFilesFirst = [];
            for (let i = 0; i < acceptedFilesFirst.length; i++) {
                const file = acceptedFilesFirst[i];
                await asyncEncodeImageFileAsURL(file)
                    .then(async (base64res: any) => {
                        newFilesFirst.push({
                            inputIndex: i,
                            id: `${new Date().getTime()}${i}`,
                            fileUrl: base64res,
                            fileName: file.name,
                            fileSize: file.size,
                            mimeType: file.type,
                        })
                    });
            }
            const sendFilesFirst = [...newFilesFirst].filter(elem => {
                const extension = elem.fileName.split('.').pop();
                if (extension) {
                    return ['pdf', 'PDF', 'docx', 'png', 'tiff', 'jpg', 'rtf', 'doc'].includes(extension.toLowerCase())
                } else {
                    return false;
                }
            })

            const acceptedFilesSecond = [structuredClone(acceptedFiles)[1]]

            const newFilesSecond = [];
            for (let i = 0; i < acceptedFilesSecond.length; i++) {
                const file = acceptedFilesSecond[i];
                await asyncEncodeImageFileAsURL(file)
                    .then(async (base64res: any) => {
                        newFilesSecond.push({
                            inputIndex: i,
                            id: `${new Date().getTime()}${i}`,
                            fileUrl: base64res,
                            fileName: file.name,
                            fileSize: file.size,
                            mimeType: file.type,
                        })
                    });
            }

            const sendFilesSecond = [...newFilesSecond].filter(elem => {
                const extension = elem.fileName.split('.').pop();
                if (extension) {
                    return ['pdf', 'PDF', 'docx', 'png', 'tiff', 'jpg', 'rtf', 'doc'].includes(extension.toLowerCase())
                } else {
                    return false;
                }
            });

            if (sendFilesFirst.length < newFilesFirst.length || sendFilesSecond.length < newFilesSecond.length) {
                const unsupportedFiles = [...newFilesFirst.filter(file => !sendFilesFirst.includes(file))
                , ...newFilesSecond.filter(file => !sendFilesSecond.includes(file))].map(file => file.fileName)
                const unsupportedFilesNames = unsupportedFiles.join(', ')
                await props.popupPush(
                    {
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: [`${unsupportedFilesNames}: неподдерживаемый формат`],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    })
            } else {
                props.uploadAndRecognizePair({firstDocument: sendFilesFirst, secondDocument: sendFilesSecond});
            }
        }
    }

    const onShowDropzone = () => {
        setTimeout(() => {
            getStoreUtils().dispatch(setUploadPopupBackground(true))
        }, 0);
    }
    const onHideDropzone = () => {
        setTimeout(() => {
            getStoreUtils().dispatch(setUploadPopupBackground(false))
        }, 0);
    }

    useEffect(() => {
        if(props.firstDocumentFiles?.length !== 0 && props.secondDocumentFiles?.length !== 0 && !props.firstDocumentLoading && !props.secondDocumentLoading) {
            props.sendPackageToRecognition();
        }
    }, [props.firstDocumentFiles, props.secondDocumentFiles, props.secondDocumentLoading, props.firstDocumentLoading]);


    return props.isBackground ?
        <div
            className={styles.popupBackground}
        >
            {(props.firstDocumentFiles.length === 0 && !props.firstDocumentLoading||props.secondDocumentFiles.length === 0 && !props.secondDocumentLoading && props.isBackground) && <DropzoneFullScreen
                files={[...props.firstDocumentFiles, ...props.secondDocumentFiles]}
                setFiles={(files: IFileFromInput[]) => {
                }}
                onDropHandler={handleFileDropBoth}
                onDragEnd={onHideDropzone}
                onDragStart={onShowDropzone}
            />}
        </div>

        : props.isOpen &&
        <div
            className={styles.popupBackground}
        >
            <div
                className={`${styles.popupContainer} ${styles.popupContainerEmptyDocList}`}>
                {props.isOpen && <IconButton
                    onClick={() => {
                        props.setUploadPopupOpen(false)
                    }}
                    className={styles.closeIcon}
                >
                </IconButton>}
                <div className={`${styles.uploadContainer}`}>
                    {(props.firstDocumentFiles.length === 0 && !props.firstDocumentLoading) && <MyDropzone
                        isEmpty={[...props.firstDocumentFiles].length === 0}
                        files={[...props.firstDocumentFiles]}
                        setFiles={(files: IFileFromInput[]) => {
                            props.setUploads(files, false, PairDocs.First)
                        }}
                        onDropHandler={handleFileDropFirst}
                        pairDoc={PairDocs.First}

                    />}
                    {props.firstDocumentLoading && <Loading/>}
                    {(props.firstDocumentFiles.length > 0 && !props.firstDocumentLoading) && <LoadSuccess/>}
                    {(props.secondDocumentFiles.length > 0 && !props.secondDocumentLoading) && <LoadSuccess/>}
                    {props.secondDocumentLoading && <Loading/>}
                    {(props.secondDocumentFiles.length === 0 && !props.secondDocumentLoading) && <MyDropzone
                        pairDoc={PairDocs.Second}
                        isEmpty={[...props.secondDocumentFiles].length === 0}
                        files={[...props.secondDocumentFiles]}
                        setFiles={(files: IFileFromInput[]) => {
                            props.setUploads(files, false, PairDocs.Second)
                        }}
                        onDropHandler={handleFileDropSecond}
                    />}
                </div>
                <div className={styles.buttonsContainer}>
                    {props.isOpen && <>
                        <Button
                            onClick={props.onClearUploads}
                            className={`${styles.cancelButton}`}
                        >
                            Очистить
                        </Button>
                    </>}
                </div>
            </div>
        </div>

}

export function Loading() {
    return <div className={styles.loadingDocument}>
        <CircularProgress/>
    </div>
}

export function LoadSuccess() {
    return <div className={styles.uploadSuccess}>
        <div className={styles.uploadSuccessLabel}>
            Файлы успешно загружены
        </div>
        <CheckCircleOutlineSharpIcon
            fontSize={"large"}
            color={'success'}
        />
    </div>
}

import {styled} from "@mui/material";

export const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding:  0 16px 16px 16px;
  box-sizing: border-box;
  
`;

export const ContainerRow = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font: normal normal 17px/20px "Roboto", sans-serif;
  color: #8697a8;
`;

export const TextRow = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const LinkElem = styled('a')`
  color: #3066F0;
  text-decoration: none;
  text-underline: none;
  font: normal normal 17px/20px "Roboto", sans-serif;
`;

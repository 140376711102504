export const materialTableLocalizationRu = {
    pagination: {
        labelDisplayedRows: "{from}-{to} из {count}",
        firstAriaLabel: "Первая страница",
        firstTooltip: "Первая страница",
        previousAriaLabel: "Предыдущая страница",
        previousTooltip: "Предыдущая страница",
        nextAriaLabel: "Следующая страница",
        nextTooltip: "Следующая страница",
        lastAriaLabel: "Последняя страница",
        lastTooltip: "Последняя страница"
    },
    toolbar: {
        searchTooltip: "Поиск",
        searchPlaceholder: "Поиск"
    },
    header: {
        actions: ""
    },
    body: {
        deleteTooltip: 'Удалить',
        emptyDataSourceMessage: "Нет записей для отображения",
        filterRow: {
            filterTooltip: "Фильтр"
        }
    }
};

import styles from './MonitoringPage.module.scss';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Table, TableProps} from "../../components/table/Table";
import PageWrapper from "../../components/pageWrapper/PageWeapper";
import {ROUTES} from "../../app/Routes";
import {
    getDifferenceRatePercent,
    getDiscrepancyRatio,
    tableCustomDateSort,
    tableCustomNumberSort,
    truncate
} from "../../helper/other";
import "moment/locale/ru";
import {DeleteRowProps, MonitoringStatuses, setOrderBy, SortDirections} from "../../ducks/montitoring";
import {Icon, ListItemIcon, Menu, MenuItem,} from "@mui/material";
import {defaultFilter, IMonitoringFilter} from "../../api/monitoringApi";
import {getStoreUtils} from "../../store/utils";
import {Column} from "material-table";
import {setUploadPopupBackground} from "../../ducks/upload";
import processing from "./processing.svg";
import twoDocOkStatus from "../../twoDocOkStatus.svg";
import twoDocsErrorStatus from "../../twoDocsErrorStatus.svg";
import processFinishOkStatus from "../../processFinishokStatus.svg";
import {ThemeProvider, withStyles} from "@mui/styles";
import {theme} from "../../theme/theme";
import {PopupElement} from "../../ducks/popup";
import { TableSortLabel } from '@mui/material';
import {setStorageOrderBy, setStorageSortType} from "../../selectors/monitoring";

export interface IMonitoringPageStateProps {
    list: IRecognitionActTable[];
    isUploadPopupOpen: boolean;
    options: string[];
    sort: "asc" | "desc" | undefined;
    orderBy: number;
    listPage: number;
    selectedRowIds: number[];
}

export interface IMonitoringPageDispatchProps {
    getRecognitionActs: (filter?: IMonitoringFilter, isSearch?: boolean) => void;
    routeTo: (route: string | null, id?: number) => void;
    setSortType: (sort: SortDirections | undefined) => void;
    setSearchOptions: (options: string[]) => void;
    setListPage: (pageNumber: number) => void;
    setOrderBy: (orderBy: number) => void;
    setSelectedRowIds: (ids: number[]) => void;
    clearMonitoringList: () => void;
    popupPush: (popup: PopupElement) => void;
    deleteTableRows: (props: DeleteRowProps) => void;
}

export type MonitoringPageProps = IMonitoringPageStateProps & IMonitoringPageDispatchProps;

export interface IRecognitionActTable {
    id: number;
    key: string;
    firstDocument: string;
    secondDocument: string;
    compareDate: string;
    comment: string;
    upload_date: string;
    status: string;
    statusId: number;
    discrepancyRatio: number;
    isSorted?: boolean;
}

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #000000',
        boxShadow: "none"
    },
})(Menu);

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontSize: '14px',
        fontWeight: 500,
    },
}))(MenuItem);

export function MonitoringPage(props: MonitoringPageProps) {
    const tableContainerRef = useRef<HTMLDivElement | null>(null);
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
        rowId: number;
    } | null>(null);
    const [orderBy, setOrderBy] = useState<TableProps['orderBy']>((props.orderBy || props.orderBy === 0) ? props.orderBy : 2);
    const [orderDirection, setOrderDirection] = useState<TableProps['orderDirection']>(props.sort ?? 'asc');

    const defaultTableColumns: Column<Object>[] = [
        {
            title: 'СТАТУС',
            field: 'status',
            sorting: true,
            customSort: (a: IRecognitionActTable, b: IRecognitionActTable) => {
                if(a.isSorted || b.isSorted) return 1;
                return +b.statusId - +a.statusId;
            },
            render: rowData => {
                const {status, statusId} = rowData as IRecognitionActTable;
                let statusIcon = processing;
                let statusLabel = status;
                switch (statusId) {
                    case MonitoringStatuses.COMPARED:
                    case MonitoringStatuses.NEW:
                    case MonitoringStatuses.SENDING:
                        statusLabel = 'Обрабатывается';
                        break;
                    case MonitoringStatuses.HAS_DIFFERENCES:
                        statusIcon = twoDocsErrorStatus;
                        break;
                    case MonitoringStatuses.HAS_NO_DIFFERENCES:
                        statusIcon = twoDocOkStatus;
                        break;
                    case MonitoringStatuses.FINISHED:
                        statusIcon = processFinishOkStatus;
                        break;
                }
                return <div
                    onClick={() => {
                    }}
                    className={styles.iconRow} title={statusLabel ?? ''}>
                    <ListItemIcon sx={{width: "24px", height: "24px"}}>
                        <Icon sx={{width: "24px", height: "24px"}}>
                            <img alt={""} src={statusIcon} width={24}
                                 height={24}/>
                        </Icon>
                    </ListItemIcon>
                </div>;
            },
        },
        {
            title: 'СООТВ.',
            tooltip: 'Процент соответствия измененного документа эталону',
            field: 'discrepancyRatio',
            sorting: true,
            customSort: (a: IRecognitionActTable, b: IRecognitionActTable) => {
                if(a.isSorted || b.isSorted) return 1;
                return tableCustomNumberSort(getDiscrepancyRatio(b.discrepancyRatio), getDiscrepancyRatio(a.discrepancyRatio));
            },
            render: rowData => {
                const {discrepancyRatio} = rowData as IRecognitionActTable;
                if(discrepancyRatio == null) {
                    return <></>;
                }

                return getDifferenceRatePercent(discrepancyRatio);
            }
        },
        {
            title: 'ДАТА ЗАГРУЗКИ',
            field: 'upload_date',
            sorting: true,

            customSort: (a:IRecognitionActTable, b:IRecognitionActTable) => {
                if(a.isSorted || b.isSorted) return 1;
                return tableCustomDateSort(
                    b.upload_date,
                    a.upload_date,
                    "DD.MM.YYYY HH:mm:ss",
                )
            }
        },
        {
            title: 'ЭТАЛОН', sorting: false, field: 'firstDocument',
            render: rowData => {
                const {firstDocument} = rowData as IRecognitionActTable;
                return <div
                    title={firstDocument}
                >
                    {truncate(firstDocument, 50)}
                </div>;
            }
        },
        {
            title: 'ИЗМЕНЕННЫЙ ДОКУМЕНТ', sorting: false, field: 'secondDocument',
            render: rowData => {
                const {secondDocument} = rowData as IRecognitionActTable;
                return <div
                    title={secondDocument}
                >
                    {truncate(secondDocument, 50)}
                </div>;
            }
        },
        {
            title: 'КОММЕНТАРИЙ', sorting: false, field: 'comment',
            render: rowData => {
                const {comment} = rowData as IRecognitionActTable;
                return <div
                    title={comment}
                >
                    {truncate(comment, 50)}
                </div>;
            }
        },
    ];

    const onOrderChange: TableProps['onOrderChange'] = (orderBy, orderDirection) => {
        setOrderBy(() => orderBy);
        setStorageSortType(orderDirection);
        setStorageOrderBy(orderBy);
        setOrderDirection(() => (orderDirection ?? undefined));
        props.setSortType(orderDirection as SortDirections);
        props.setOrderBy(orderBy);
        // props.setListPage(0);
    }
    const onSelectRow = (rows: IRecognitionActTable[], rowData?: IRecognitionActTable) => {
        const selectedIds: number[] = structuredClone(props.selectedRowIds);
        if(rowData){
            const findIndex = selectedIds.findIndex((el) => rowData?.id === el);
            if(findIndex >= 0) {
                selectedIds.splice(findIndex,1);
            } else {
                selectedIds.push(rowData?.id);
            }
            props.setSelectedRowIds(selectedIds);
        } else {
            if(rows.length === props.list.length) {
                props.setSelectedRowIds(rows.map(el => el.id));
            } else {
                props.setSelectedRowIds([]);
            }
        }
    }
    const onSetPage = useCallback((pageNumber: number) => {
        props.setListPage(pageNumber)
    }, []);

    const onRowClick = useCallback((_, rowData: IRecognitionActTable) => {
        const { key, statusId} = rowData as IRecognitionActTable;
        if(statusId !== MonitoringStatuses.UPLOADING) {
            props.routeTo(`${ROUTES.Docs}/${key}`);
        }
    }, []);

    const onContextMenu = useCallback((event: MouseEvent, rowData: IRecognitionActTable) => {
        event.preventDefault();
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 5,
                    mouseY: event.clientY + 15,
                    rowId: rowData.id,
                }
                :
                null,
        );

    }, [contextMenu]);
    const tableProps: TableProps = {
        pageSize: 25,
        selection: true,
        onChangeListPage: onSetPage,
        initialPage: props.listPage,
        columns: defaultTableColumns,
        onRowClick: onRowClick,
        onContextMenu: onContextMenu,
        selectedIds: props.selectedRowIds,
        onSelectRow,
        orderBy,
        orderDirection,
        onOrderChange,
    };

    useEffect(() => {
        return () => {
            props.clearMonitoringList();
        }
    }, [])

    useEffect(() => {
        if (!props.isUploadPopupOpen) {
            props.getRecognitionActs(defaultFilter);
        }
    }, []);

    const onShowDropzone = () => {
        setTimeout(() => {
            getStoreUtils().dispatch(setUploadPopupBackground(true))
        }, 0);
    }

    const onDeleteTableRows = () => {
        setContextMenu(null);
        props.popupPush({
            actionTitle: "Да",
            cancelTitle: 'Отмена',
            actionVisible: true,
            data: [`Вы действительно хотите удалить выбранные документы?`],
            actionHandler: () => {
                if(contextMenu?.rowId)
                props.deleteTableRows({
                    pairIds: props.selectedRowIds.includes(contextMenu.rowId)
                        ? props.selectedRowIds
                        : [contextMenu.rowId, ...props.selectedRowIds]
                })
            },
            cancelVisible: true,
        });
    }

    return <PageWrapper>
        <div
            ref={tableContainerRef}
            onDragEnter={onShowDropzone}
            className={styles.table}
        >
            <StyledMenu
                id="simple-menu"
                open={contextMenu !== null}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                onClose={(e) => {
                    setContextMenu(() => null);
                }}>
                <StyledMenuItem
                    onClick={onDeleteTableRows}
                >
                    {(props.selectedRowIds.length > 1 || (props.selectedRowIds.length === 1 && !props.selectedRowIds.includes(contextMenu?.rowId))) ? 'Удалить выбранное' : 'Удалить'}
                </StyledMenuItem>
            </StyledMenu>
            <ThemeProvider theme={theme}>
                <Table {...tableProps} data={structuredClone(props.list)} bodyHeight={(tableContainerRef && tableContainerRef.current) ? tableContainerRef.current.clientHeight : 0}/>
            </ThemeProvider>
        </div>
    </PageWrapper>
}

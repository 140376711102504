import {Thunk, TypedAction} from "../store/types";
import {popupPush, spinnerMinusStatus, spinnerPlusStatus} from "./popup";
import {
    CreateHashProps,
    CreateHashRes,
    defaultFilter,
    getMonitoringApiObj,
    IDifferenceData,
    IDiscrepancyStatus,
    IDocumentImage,
    IDocumentImageId,
    IImageId,
    IImagesIds,
    IMonitoringFilter,
    IMonitoringItem,
    INotRecognizedPackage,
} from "../api/monitoringApi";
import {
    selectDetailedPair,
    selectDifferenceBarPosition,
    selectDifferenceData,
    selectDocFilterList,
    selectFirstDocumentPosition,
    selectLeftImages,
    selectMonitoringList,
    selectPairId,
    selectRightImages,
    setStoragePage
} from "../selectors/monitoring";
import {getUploadApiObj, IConvertedImage} from "../api/uploadApi";
import {asyncEncodeImageFileAsURL, defaultSortColumnIndex, getIsDetailed} from "../helper/other";
import {DifferenceBarPosition, FirstDocumentPosition} from "../components/navigationBar/navigationBar";
import {selectPath, selectUrlId} from "../selectors/other";
import {ROUTES} from "../app/Routes";
import {push} from "connected-react-router";
import {customControllerUtils} from "../abortController/abortController";
import {selectComment} from "../selectors/upload";
import {SET_COMMENT} from "./upload";
import {getBalanceApiObj} from "../api/balanceApi";
import {IBalance, SET_USER_BALANCE} from "./auth";
import moment from "moment/moment";
import {CommentInputProps} from "../components/DifferenceListSidebar/CommentInput";

export interface IExchangeParams {
    panelPosition: DifferenceBarPosition | undefined;
    docPosition: FirstDocumentPosition | undefined;
    docFilterList: string[];
}

export type DeleteRowProps = {
    pairIds: number[];
}

export enum MonitoringStatuses {
    NEW = 0,
    SENDING = 1,
    COMPARED = 2,
    HAS_DIFFERENCES = 3,
    HAS_NO_DIFFERENCES = 4,
    FINISHED = 5,
    DELETED = 6,
    UPLOADING = 7,
}

export const MONITORING_STATUSES = {
    0: "Новый",
    1: "Отправляется",
    2: "Сравнение",
    3: "Есть расхождения",
    4: "Нет расхождений",
    5: "Завершен",
    6: "Удален",
    7: "Загрузка",
}

export interface ITechData {
    recognitionServiceFirstDocumentId: number,
    recognitionServiceSecondDocumentId: number,
}

export interface IDetailedPair {
    id: number;
    discrepancies: IDifferenceData[];
    discrepancyRatio: number;
    state: GET_DOCUMENTS_STATE;
    statusId: number;
    techData: ITechData,
    comment: string;
    files: {
        mimeType: string;
        name: string;
        place: number;
    }[];
}

export enum GET_DOCUMENTS_STATE {
    Done = "Done",
    Other = 'Other',
    Comparing = 'Comparing',
    QueuedToComparing = 'QueuedToComparing',
    Recognizing = 'Recognizing',
    Created = 'Created',
    Error = 'Error',
    NotFound = 'NotFound',
}

export enum DocNumber {
    FIRST,
    SECOND
}

export enum SortDirections {
    ASC = "asc",
    DESC = "desc"
}

export enum PackageStatuses {
    RECOGNITION = 3,
    RECOGNIZED = 9
}

export interface IDifferencePageScaleState {
    leftScale: number;
    rightScale: number;
}

const SET_LOADS = "MONITORING/SET_LOADS";
const SET_SEARCH_OPTIONS = "MONITORING/SET_SEARCH_OPTIONS";
const SET_DETAILED_DIFF = "MONITORING/SET_DETAILED_DIFF";
const SET_FIRST_DOC_POSITION = "MONITORING/SET_FIRST_DOC_POSITION";
const SET_DIFFERENCE_BAR_POSITION = "MONITORING/SET_DIFFERENCE_BAR_POSITION";
const SET_SCROLL_SYNC = "MONITORING/SET_SCROLL_SYNC";
const SET_MENU_OPEN = "MONITORING/SET_MENU_OPEN";
const SET_COMMENT_INPUT_SHOWN = "MONITORING/SET_COMMENT_INPUT_SHOWN";
const SET_DETAILED_DOCUMENTS = "MONITORING/SET_DETAILED_DOCUMENTS";
const SET_DOCUMENT_IMAGES_RIGHT = "MONITORING/SET_DOCUMENT_IMAGES_RIGHT";
const SET_DOCUMENT_IMAGES_LEFT = "MONITORING/SET_DOCUMENT_IMAGES_LEFT";
const SET_DETAILED_DOCUMENT_ID_LEFT = "MONITORING/SET_DETAILED_DOCUMENT_ID_LEFT";
const SET_DETAILED_DOCUMENT_ID_RIGHT = "MONITORING/SET_DETAILED_DOCUMENT_ID_RIGHT";
const SET_LEFT_SCALE = "MONITORING/SET_LEFT_SCALE";
const SET_RIGHT_SCALE = "MONITORING/SET_RIGHT_SCALE";
const SET_IS_TRACK_DISABLED = "MONITORING/SET_IS_TRACK_DISABLED";
const SET_ZOOM_COUNT = "MONITORING/SET_ZOOM_COUNT";
const SET_SORT_TYPE = "MONITORING/SET_SORT_TYPE";
const SET_ORDER_BY = "MONITORING/SET_ORDER_BY";
const SET_LIST_PAGE = "MONITORING/SET_LIST_PAGE";
const SET_DIFFERENCE_DATA = "MONITORING/SET_DIFFERENCE_DATA";
const SET_DETAILED_PAIR = "MONITORING/SET_DETAILED_PAIR";
const SET_MAGNIFIER_ACTIVE = "MONITORING/SET_MAGNIFIER_ACTIVE";
const SET_PAIR_ID = "MONITORING/SET_PAIR_ID";
const UPDATE_DOC_FILTER_LIST = "MONITORING/UPDATE_DOC_FILTER_LIST";
const SET_IS_CHANGING_DETAILED_DIFF = "SET_IS_CHANGING_DETAILED_DIFF";
const SET_IS_CHANGING_VIEW = "SET_IS_CHANGING_VIEW";
const SET_IS_PAGE_LOADING = "SET_IS_PAGE_LOADING";
const SET_SELECTED_ROW_IDS = "MONITORING/SET_SELECTED_ROW_IDS";
const SET_PAIR_COMMENT = "MONITORING/SET_PAIR_COMMENT";
const SET_DIFFERENCE_COMMENT = "MONITORING/SET_DIFFERENCE_COMMENT";
const SET_DIFFERENCE_LINK = "MONITORING/SET_DIFFERENCE_LINK";

export type MonitoringActions =
    | TypedAction<typeof SET_LOADS, IMonitoringItem[]>
    | TypedAction<typeof SET_DOCUMENT_IMAGES_RIGHT, IDocumentImage[]>
    | TypedAction<typeof SET_DOCUMENT_IMAGES_LEFT, IDocumentImage[]>
    | TypedAction<typeof SET_DIFFERENCE_BAR_POSITION, DifferenceBarPosition>
    | TypedAction<typeof SET_FIRST_DOC_POSITION, FirstDocumentPosition>
    | TypedAction<typeof SET_DETAILED_DOCUMENT_ID_RIGHT, string | undefined>
    | TypedAction<typeof SET_DETAILED_PAIR, IDetailedPair | undefined>
    | TypedAction<typeof UPDATE_DOC_FILTER_LIST, string[]>
    | TypedAction<typeof SET_LEFT_SCALE, number>
    | TypedAction<typeof SET_RIGHT_SCALE, number>
    | TypedAction<typeof SET_ZOOM_COUNT, number>
    | TypedAction<typeof SET_DETAILED_DOCUMENT_ID_LEFT, string | undefined>
    | TypedAction<typeof SET_DETAILED_DIFF, string | undefined>
    | TypedAction<typeof SET_PAIR_ID, number | undefined>
    | TypedAction<typeof SET_IS_TRACK_DISABLED, boolean>
    | TypedAction<typeof SET_MAGNIFIER_ACTIVE, boolean>
    | TypedAction<typeof SET_IS_CHANGING_DETAILED_DIFF, boolean>
    | TypedAction<typeof SET_IS_PAGE_LOADING, IMonitoringState['pageLoadingState']>
    | TypedAction<typeof SET_MENU_OPEN, boolean>
    | TypedAction<typeof SET_IS_CHANGING_VIEW, boolean>
    | TypedAction<typeof SET_DIFFERENCE_DATA, IDifferenceData[]>
    | TypedAction<typeof SET_SORT_TYPE, string | undefined>
    | TypedAction<typeof SET_SEARCH_OPTIONS, string[]>
    | TypedAction<typeof SET_COMMENT_INPUT_SHOWN, boolean>
    | TypedAction<typeof SET_SCROLL_SYNC, boolean>
    | TypedAction<typeof SET_SELECTED_ROW_IDS, number[]>
    | TypedAction<typeof SET_LIST_PAGE, number>
    | TypedAction<typeof SET_ORDER_BY, number>
    | TypedAction<typeof SET_DIFFERENCE_COMMENT, DifferenceCommentState | undefined>
    | TypedAction<typeof SET_DIFFERENCE_LINK, DifferenceLinkState | undefined>
    | TypedAction<typeof SET_PAIR_COMMENT, string>

export type IMonitoringState = {
    list: IMonitoringItem[];
    selectedDiff: string | undefined;
    leftScale: number;
    rightScale: number;
    differenceData: IDifferenceData[];
    isCommentInputShown: boolean;
    detailedDocumentIdLeft: string;
    detailedDocumentIdRight: string;
    pairId: number | undefined;
    leftDetailedImages: IDocumentImage[];
    rightDetailedImages: IDocumentImage[];
    isTrackDisabled: boolean;
    isMagnifierActive: boolean;
    searchOptions: string[];
    sortType: SortDirections | undefined;
    sortOrderBy: number;
    listPage: number;
    zoomCount: number;
    scrollSync: boolean;
    isChangingView: boolean;
    docFilterList: string[];
    menuOpen: boolean;
    pageLoadingState: {
        isLoading: boolean;
        isComparing: boolean;
    } | undefined;
    isChangingDetailedDiff: boolean;
    panelPosition: DifferenceBarPosition | undefined;
    docPosition: FirstDocumentPosition | undefined;
    detailedPair: IDetailedPair | undefined;
    selectedRowIds: number[];
    differenceComment: DifferenceCommentState | undefined,
    differenceLink: DifferenceLinkState | undefined,
}

export enum Directions {
    Right = 'right',
    Left = 'left',
}

type DifferenceCommentState = Omit<CommentInputProps, 'topIndex'>;

type DifferenceLinkState = {
    hash: string;
};


export const initialState: IMonitoringState = {
    list: [],
    selectedDiff: undefined,
    isCommentInputShown: false,
    leftDetailedImages: [],
    rightDetailedImages: [],
    detailedDocumentIdLeft: undefined,
    detailedDocumentIdRight: undefined,
    leftScale: 1,
    rightScale: 1,
    isTrackDisabled: false,
    isMagnifierActive: false,
    searchOptions: [],
    sortType: SortDirections.DESC,
    listPage: 0,
    differenceData: [],
    scrollSync: true,
    menuOpen: true,
    panelPosition: undefined,
    docPosition: undefined,
    detailedPair: undefined,
    pairId: undefined,
    isChangingDetailedDiff: false,
    isChangingView: false,
    pageLoadingState: undefined,
    zoomCount: 100,
    docFilterList: [],
    selectedRowIds: [],
    differenceComment: undefined,
    differenceLink: undefined,
    sortOrderBy: 2,

}

export function addMockPair(firstDocName: string, secondDocName: string): Thunk {
    return (dispatch, getState) => {
        const state = getState();
        const list = selectMonitoringList(state);
        list.unshift({
            comment: '',
            id: 0,
            state: GET_DOCUMENTS_STATE.Created,
            status: {
                id: MonitoringStatuses.UPLOADING
            },
            images: [
                {
                    mimeType: '',
                    place: 1,
                    name: firstDocName,
                },
                {
                    mimeType: '',
                    place: 2,
                    name: secondDocName,
                },
            ],
            compareDate: null,
            discrepanciesData: [],
            key: `${new Date().getTime()}`,
            createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: null,
            discrepancyRatio: 0,
        })
        dispatch({type: SET_LOADS, payload: list});
    }
}


export function setDetailedDiff(diff: string) {
    return dispatch => {
        dispatch({type: SET_DETAILED_DIFF, payload: diff});
    }
}

export function setSelectedRowIds(ids: number[]) {
    return dispatch => {
        dispatch({type: SET_SELECTED_ROW_IDS, payload: ids});
    }
}

export function setDifferenceCommentPopup(state: DifferenceCommentState | undefined) {
    return dispatch => {
        dispatch({type: SET_DIFFERENCE_COMMENT, payload: state});
    }
}
export function setDifferenceLinkPopup(state: DifferenceLinkState | undefined) {
    return dispatch => {
        dispatch({type: SET_DIFFERENCE_LINK, payload: state});
    }
}

export function updateDocFilterList(list: string[]) {
    return dispatch => {
        dispatch({type: UPDATE_DOC_FILTER_LIST, payload: list});
    }
}

export function setIsChangingDetailedDiff(flag: boolean) {
    return dispatch => {
        dispatch({type: SET_IS_CHANGING_DETAILED_DIFF, payload: flag});
    }
}

export function setIsPageLoading(props: IMonitoringState['pageLoadingState']) {
    return dispatch => {
        dispatch({type: SET_IS_PAGE_LOADING, payload: props});
    }
}

export function setIsMagnifierActive(flag: boolean) {
    return dispatch => {
        dispatch({type: SET_MAGNIFIER_ACTIVE, payload: flag});
    }
}

export function setScrollSync(flag: boolean) {
    return dispatch => {
        dispatch({type: SET_SCROLL_SYNC, payload: flag});
    }
}

export function setZoomCount(zoom: number) {
    return dispatch => {
        dispatch({type: SET_IS_CHANGING_VIEW, payload: true});
        dispatch({type: SET_ZOOM_COUNT, payload: zoom});
        setTimeout(() => {
            dispatch({type: SET_IS_CHANGING_VIEW, payload: false});
        }, 250)
    }
}

export function setDifferenceBarPosition(position: DifferenceBarPosition) {
    return dispatch => {
        dispatch({type: SET_IS_CHANGING_VIEW, payload: true});
        dispatch({type: SET_DIFFERENCE_BAR_POSITION, payload: position});
        setTimeout(() => {
            dispatch({type: SET_IS_CHANGING_VIEW, payload: false});
        }, 25)
    }
}

export function setFirstDocumentPosition(position: FirstDocumentPosition) {
    return dispatch => {
        dispatch({type: SET_IS_CHANGING_VIEW, payload: true});
        dispatch({type: SET_FIRST_DOC_POSITION, payload: position});
        setTimeout(() => {
            dispatch({type: SET_IS_CHANGING_VIEW, payload: false});
        }, 25)
    }
}

export function setSortType(type: SortDirections | undefined) {
    return dispatch => {
        dispatch({type: SET_SORT_TYPE, payload: type});
    }
}

export function setOrderBy(orderBy: number) {
    return dispatch => {
        dispatch({type: SET_ORDER_BY, payload: orderBy});
    }
}

export function setSearchOptions(options: string[]) {
    return dispatch => {
        dispatch({type: SET_SEARCH_OPTIONS, payload: options});
    }
}

export function setMenuOpen(flag: boolean) {
    return dispatch => {
        dispatch({type: SET_IS_CHANGING_VIEW, payload: true});
        dispatch({type: SET_MENU_OPEN, payload: flag});
        setTimeout(() => {
            dispatch({type: SET_IS_CHANGING_VIEW, payload: false});
        }, 30)
    }
}

export function setDifferenceStatus(index: number, status: IDiscrepancyStatus): Thunk {
    return async (dispatch, getState) => {
        const pair = selectDetailedPair(getState());
        const diffs = selectDifferenceData(getState())
        if (pair) {
            diffs[index].important = status.important;
            dispatch({
                type: SET_DIFFERENCE_DATA,
                payload: [...diffs],
            })
            await getMonitoringApiObj()
                .updateDocuments(pair.id, {...pair, comment: pair.comment ? pair.comment : "", discrepancies: diffs})
                .then(async () => {
                })
                .catch((err) => {
                    try {
                        const error = JSON.parse(err);
                        dispatch(popupPush({
                            isDefaultError: true,
                            actionTitle: "Хорошо",
                            actionVisible: true,
                            data: [error.message ? error.message : "Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    } catch (e) {
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            isDefaultError: true,
                            actionVisible: true,
                            data: ["Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    }
                })
        }
    }
}

export function setDifferenceAllStatus(status: IDiscrepancyStatus): Thunk {
    return async (dispatch, getState) => {
        const pair = selectDetailedPair(getState());
        const diffs = selectDifferenceData(getState())
        if (pair) {
            const newDiffs = diffs.map(el => ({...el, important: status?.important}))
            dispatch({
                type: SET_DIFFERENCE_DATA,
                payload: [...newDiffs],
            })
            await getMonitoringApiObj()
                .updateDocuments(pair.id, {...pair, comment: pair.comment ? pair.comment : "", discrepancies: newDiffs})
                .then(async () => {
                })
                .catch((err) => {
                    try {
                        const error = JSON.parse(err);
                        dispatch(popupPush({
                            isDefaultError: true,
                            actionTitle: "Хорошо",
                            actionVisible: true,
                            data: [error.message ? error.message : "Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    } catch (e) {
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            isDefaultError: true,
                            actionVisible: true,
                            data: ["Произошла ошибка"],
                            actionHandler: () => {
                            },
                            cancelVisible: false,
                        }))
                    }
                })
        }
    }
}

export function updatePairStatus(status: MonitoringStatuses): Thunk {
    return async (dispatch, getState) => {
        const pair = selectDetailedPair(getState());
        const diffs = selectDifferenceData(getState())
        if (pair) {
            dispatch(spinnerPlusStatus());
            await getMonitoringApiObj()
                .updateDocuments(pair.id, {...pair, discrepancies: diffs, statusId: status})
                .then(async () => {
                    await getMonitoringApiObj()
                        .getDocuments(pair.id)
                        .then((res) => res.json())
                        .then(res => {
                            if (!res) return;
                        })
                        .catch((e) => console.log(e))

                })
                .finally(() => {
                    dispatch(spinnerMinusStatus());
                    dispatch(push(ROUTES.Home));
                })
        }
        dispatch(spinnerMinusStatus());
    }
}

export function setDifferenceData(data: IDifferenceData[]): Thunk {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_DIFFERENCE_DATA,
            payload: data,
        })
    }
}

export function updateComment(status?: number): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus())
        const pair = selectDetailedPair(getState());
        const diffs = selectDifferenceData(getState())
        if (pair) {
            await getMonitoringApiObj()
                .updateDocuments(pair.id, {
                    ...pair,
                    discrepancies: diffs,
                    comment: selectComment(getState()),
                    statusId: status ? status : pair.statusId
                })
                .then(async () => {
                })
                .catch((error) => {
                    console.log("update_pair_error", error)
                })
                .finally(() => {
                    dispatch(spinnerMinusStatus())
                })
        }
        dispatch(spinnerMinusStatus())
    }
}

export function getStatusName(name: MonitoringStatuses): string {
    const status = MONITORING_STATUSES[name];
    return status ? status : "";
}

export function getCompareList(filter: IMonitoringFilter = defaultFilter, isSearch: boolean = false): Thunk {
    return async (dispatch, getState) => {
        const path = selectPath(getState());
        !isSearch && (path !== ROUTES.Doc) && dispatch(spinnerPlusStatus());
        const newFilter: IMonitoringFilter = structuredClone(filter);
        const getMonitoringList = async () => {
            await getMonitoringApiObj()
                .getList({...newFilter})
                .then(res => res.json())
                .then((res) => {
                    try {
                        let acts: IMonitoringItem[] = res;
                        let filteredActs: IMonitoringItem[];
                        filteredActs = [...acts]
                        dispatch({type: SET_LOADS, payload: filteredActs});
                    } catch (e) {
                        console.log("e", e)
                    }
                })
                .catch((error) => {
                    dispatch({type: SET_LOADS, payload: []});
                    console.log("sendPackageToRecognition", error)
                })
                .finally(() => {
                    dispatch(spinnerMinusStatus())
                });
        }
        //@ts-ignore
        if (window.REACT_APP_HIDE_BALANCE) {
            await getMonitoringList();
        } else {
            await getBalanceApiObj()
                .getAll()
                .then(response => response.json())
                .then(async response => {
                    if (!response) return;
                    const balance: IBalance = response;
                    dispatch({
                        type: SET_USER_BALANCE,
                        payload: balance.pagesLeft ? balance.pagesLeft : 0,
                    })
                    await getMonitoringList();
                })
                .catch((err) => {
                    console.log('get balance error', err)
                })
        }
        dispatch(spinnerMinusStatus())
    }
}

export function getExtendParams(): Thunk {
    return async (dispatch, getState) => {
        await getMonitoringApiObj()
            .getExtendParams()
            .then(res => res.json())
            .then((res: IExchangeParams) => {
                try {
                    if (res) {
                        if (res.docFilterList) {
                            dispatch({type: UPDATE_DOC_FILTER_LIST, payload: res.docFilterList});
                        }
                        if (res.panelPosition) {
                            dispatch(setDifferenceBarPosition(res.panelPosition));
                        }
                        if (res.docPosition) {
                            dispatch(setFirstDocumentPosition(res.docPosition));
                        }
                    }
                } catch {

                }
            })
            .catch((err) => {
                console.log("create pair error", err)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus());
            })
    }
}
export function deleteTableRows(props: DeleteRowProps): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus());
        await getMonitoringApiObj()
            .deleteRows(props)
            .then(() => {
                dispatch(setSelectedRowIds([]));
                dispatch(getCompareList());
            })
            .catch((err) => {
                dispatch(popupPush({
                    isDefaultError: true,
                    actionVisible: true,
                    data: [],
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                }))
            })
            .finally(() => {
                dispatch(spinnerMinusStatus());
            })
    }
}

export function setExtendParams(): Thunk {
    return async (dispatch, getState) => {
        const panelPosition = selectDifferenceBarPosition(getState());
        const docPosition = selectFirstDocumentPosition(getState());
        const docFilterList = selectDocFilterList(getState());
        await getMonitoringApiObj()
            .setExtendParams({
                panelPosition,
                docPosition,
                docFilterList,
            })
            .then((res) => {
            })
            .catch((err) => {
                console.log("create pair error", err)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus());
            })
    }
}

export function clearMonitoringList(): Thunk {
    return (dispatch, getState) => {
        dispatch({type: SET_LOADS, payload: []});
    }
}
export function createHash(props: CreateHashProps): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus())
        await getMonitoringApiObj()
            .createHash(props)
            .then((res) => res.json())
            .then((res: CreateHashRes) => {
                if(!res) return;
                dispatch(setDifferenceLinkPopup({hash: res.hash}));

            })
            .catch(() => {
            })
            .finally(() => {
                dispatch(spinnerMinusStatus());
            })
    }
}

export function getDocs(id: number): Thunk {
    return async (dispatch, getState) => {
        if(!getIsDetailed())return;
        await getMonitoringApiObj()
            .getDocuments(id)
            .then((response) => response.json())
            .then((res) => {
                if (!res) return;
                const serverResponse: IDetailedPair = res;
                dispatch(setIsPageLoading({isComparing: serverResponse.state !== GET_DOCUMENTS_STATE.Done, isLoading: serverResponse.state === GET_DOCUMENTS_STATE.Done}))

                if (serverResponse.state === GET_DOCUMENTS_STATE.Done) {
                    const data: IDifferenceData[] = serverResponse.discrepancies;
                    dispatch({
                        type: SET_DETAILED_PAIR,
                        payload: {id, ...res},
                    })
                    dispatch({
                        type: SET_COMMENT,
                        payload: serverResponse.comment,
                    })
                    dispatch({
                        type: SET_DIFFERENCE_DATA,
                        payload: data.map((elem, index) => ({...elem, indexFromApi: index, comment: elem.comment || ''})),
                    })
                    dispatch(getImages(id));
                } else if ([GET_DOCUMENTS_STATE.Error, GET_DOCUMENTS_STATE.NotFound].includes(serverResponse.state)) {
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        isDefaultError: true,
                        actionVisible: true,
                        data: ["Произошла ошибка"],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                } else {
                    if(getIsDetailed()){
                        setTimeout(async () => {
                            dispatch(getDocs(id))
                        }, 5000);
                    }
                }
            })
            .catch((err) => {
                if(!err?.message?.includes('abort')) {
                    try {
                        const error = JSON.parse(err);
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            actionVisible: true,
                            isDefaultError: true,
                            data: [error.message ? error.message : "Произошла ошибка"],
                            actionHandler: () => {
                                dispatch(push(ROUTES.Home));
                            },
                            cancelVisible: false,
                        }))
                    } catch (e) {
                        dispatch(popupPush({
                            actionTitle: "Хорошо",
                            actionVisible: true,
                            data: ["Произошла ошибка"],
                            isDefaultError: true,
                            actionHandler: () => {
                                dispatch(push(ROUTES.Home));
                            },
                            cancelVisible: false,
                        }))
                    }
                }
                console.log('getDocs', err?.message )
            })
            .finally(() => {
            })

    }
}
export function getPublicDocs(hash: string): Thunk {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_PAIR_ID,
            payload: hash,
        })
        await getMonitoringApiObj()
            .getPublicDocuments(hash)
            .then((response) => response.json())
            .then((res) => {
                if (!res) return;
                const serverResponse: IDetailedPair = res;
                dispatch(setIsPageLoading({isComparing: serverResponse.state !== GET_DOCUMENTS_STATE.Done, isLoading: serverResponse.state === GET_DOCUMENTS_STATE.Done}))
                if (serverResponse.state === GET_DOCUMENTS_STATE.Done) {
                    const data: IDifferenceData[] = serverResponse.discrepancies;
                    dispatch({
                        type: SET_DETAILED_PAIR,
                        payload: {id: hash, ...res},
                    })
                    dispatch({
                        type: SET_COMMENT,
                        payload: serverResponse.comment,
                    })
                    dispatch({
                        type: SET_DIFFERENCE_DATA,
                        payload: data.map((elem, index) => ({...elem, indexFromApi: index, comment: elem.comment || ''})),
                    })
                    dispatch(getPublicImages(hash));
                } else if ([GET_DOCUMENTS_STATE.Error, GET_DOCUMENTS_STATE.NotFound].includes(serverResponse.state)) {
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        isDefaultError: true,
                        data: ["Произошла ошибка"],
                        actionHandler: () => {
                        },
                        cancelVisible: false,
                    }))
                } else {
                    setTimeout(async () => {
                        dispatch(getPublicDocs(hash))
                    }, 5000)
                }
            })
            .catch((err) => {
                try {
                    const error = JSON.parse(err);
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        isDefaultError: true,
                        data: [error.message ? error.message : "Произошла ошибка"],
                        actionHandler: () => {
                            dispatch(push(ROUTES.Home))
                        },
                        cancelVisible: false,
                    }))
                } catch (e) {
                    dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: ["Произошла ошибка"],
                        isDefaultError: true,
                        actionHandler: () => {
                            dispatch(push(ROUTES.Home))
                        },
                        cancelVisible: false,
                    }))
                }

                console.log('sendTechnicalReport', err)
            })
            .finally(() => {
            })

    }
}

export function getImages(id: number): Thunk {
    return async (dispatch, getState) => {
        const getDocumentImagesByArrayOfIds = async (ids: IImageId[], docNumber: DocNumber) => {
            ids.forEach((imageId) => {
                getMonitoringApiObj()
                    .getImage(imageId.id)
                    .then((res) => res.blob())
                    .then(async (response) => {
                        asyncEncodeImageFileAsURL(response).then(async (base64res: any) => {
                            let list = docNumber === DocNumber.FIRST ? selectLeftImages(getState()) : selectRightImages(getState());

                            const newImageObj: IDocumentImage = {
                                imageId: imageId.id,
                                fileUrl: base64res,
                                loading: false,
                            }

                            if (docNumber === DocNumber.FIRST) {
                                const findIndex = list.findIndex(elem => elem.imageId === imageId.id);
                                list[findIndex] = newImageObj;
                                dispatch({
                                    type: SET_DOCUMENT_IMAGES_LEFT,
                                    payload: [...list]
                                })
                            } else {
                                const findIndex = list.findIndex(elem => elem.imageId === imageId.id);
                                list[findIndex] = newImageObj;
                                dispatch({
                                    type: SET_DOCUMENT_IMAGES_RIGHT,
                                    payload: [...list],
                                })
                            }
                        })
                    })
            })
        }
        await getMonitoringApiObj()
            .getImages(id)
            .then((response) => response.json())
            .then(async (res) => {
                const imagesId: IImagesIds = res;
                const leftImages: IDocumentImage[] = imagesId.firstDocumentImages.map(elem => ({
                    imageId: elem.id,
                    fileUrl: "",
                    loading: true,
                }));
                const rightImages: IDocumentImage[] = imagesId.secondDocumentImages.map(elem => ({
                    imageId: elem.id,
                    fileUrl: "",
                    loading: true,
                }));
                dispatch({
                    type: SET_DOCUMENT_IMAGES_LEFT,
                    payload: leftImages
                })
                dispatch({
                    type: SET_DOCUMENT_IMAGES_RIGHT,
                    payload: rightImages
                })
                Promise.allSettled(
                    [
                        new Promise((resolve, reject) => {
                            resolve(getDocumentImagesByArrayOfIds(imagesId.firstDocumentImages, DocNumber.FIRST))
                        }),
                        new Promise((resolve, reject) => {
                            resolve(getDocumentImagesByArrayOfIds(imagesId.secondDocumentImages, DocNumber.SECOND))
                        })
                    ]
                )
            })

            .catch((error) => {
                console.log("getImages", error)
            })
            .finally(() => {
                dispatch(setIsPageLoading(undefined))

            })
        dispatch(setIsPageLoading(undefined))
    }
}
export function getPublicImages(hash: string): Thunk {
    return async (dispatch, getState) => {
        const getDocumentImagesByArrayOfIds = async (ids: IImageId[], docNumber: DocNumber) => {
            ids.forEach((imageId) => {
                getMonitoringApiObj()
                    .getPublicImage(imageId.id, hash)
                    .then((res) => res.blob())
                    .then(async (response) => {
                        asyncEncodeImageFileAsURL(response).then(async (base64res: any) => {
                            let list = docNumber === DocNumber.FIRST ? selectLeftImages(getState()) : selectRightImages(getState());

                            const newImageObj: IDocumentImage = {
                                imageId: imageId.id,
                                fileUrl: base64res,
                                loading: false,
                            }

                            if (docNumber === DocNumber.FIRST) {
                                const findIndex = list.findIndex(elem => elem.imageId === imageId.id);
                                list[findIndex] = newImageObj;
                                dispatch({
                                    type: SET_DOCUMENT_IMAGES_LEFT,
                                    payload: [...list]
                                })
                            } else {
                                const findIndex = list.findIndex(elem => elem.imageId === imageId.id);
                                list[findIndex] = newImageObj;
                                dispatch({
                                    type: SET_DOCUMENT_IMAGES_RIGHT,
                                    payload: [...list],
                                })
                            }
                        })
                    })
            })
        }
        await getMonitoringApiObj()
            .getPublicImages(hash)
            .then((response) => response.json())
            .then(async (res) => {
                const imagesId: IImagesIds = res;
                const leftImages: IDocumentImage[] = imagesId.firstDocumentImages.map(elem => ({
                    imageId: elem.id,
                    fileUrl: "",
                    loading: true,
                }));
                const rightImages: IDocumentImage[] = imagesId.secondDocumentImages.map(elem => ({
                    imageId: elem.id,
                    fileUrl: "",
                    loading: true,
                }));
                dispatch({
                    type: SET_DOCUMENT_IMAGES_LEFT,
                    payload: leftImages
                })
                dispatch({
                    type: SET_DOCUMENT_IMAGES_RIGHT,
                    payload: rightImages
                })
                Promise.allSettled(
                    [
                        new Promise((resolve, reject) => {
                            resolve(getDocumentImagesByArrayOfIds(imagesId.firstDocumentImages, DocNumber.FIRST))
                        }),
                        new Promise((resolve, reject) => {
                            resolve(getDocumentImagesByArrayOfIds(imagesId.secondDocumentImages, DocNumber.SECOND))
                        })
                    ]
                )
            })

            .catch((error) => {
                console.log("getImages", error)
            })
            .finally(() => {
                dispatch(setIsPageLoading(undefined))

            })
        dispatch(setIsPageLoading(undefined))
    }
}

export function getDetailedDocumentsImages(documentId: string, direction: Directions = Directions.Left): Thunk {
    return async (dispatch, getState) => {
        await getMonitoringApiObj()
            .getDocumentImage(documentId)
            .then(res => res.json())
            .then((res) => {
                const imagesRawArray: IDocumentImageId[] = res;
                const images: IDocumentImage[] = imagesRawArray.map(elem => (
                    {
                        imageId: 1,
                        fileUrl: `data:image/jpg;base64,${elem.finalImgBase64}`,
                        loading: false,
                    }
                ))
                if (direction === Directions.Right) {
                    dispatch({
                        type: SET_DOCUMENT_IMAGES_RIGHT,
                        payload: images,
                    })
                } else {
                    dispatch({
                        type: SET_DOCUMENT_IMAGES_LEFT,
                        payload: images,
                    })
                }
            })
            .catch((error) => {
                console.log("getDetailedDocumentsImages", error)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function getDocIdByKey(key: string): Thunk {
    return async (dispatch, getState) => {
        dispatch(setIsPageLoading({isLoading: false, isComparing: false}));
        dispatch({type: SET_DETAILED_PAIR, payload: undefined});
        await getMonitoringApiObj()
            .getDocIdByKey(key)
            .then(res => res.json())
            .then((res) => {
                const docId: { id: number } = res;
                dispatch({
                    type: SET_PAIR_ID,
                    payload: docId.id,
                })
                dispatch(getDocs(docId.id));
            })
            .catch((error) => {
                let errorText = "Произошла ошибка";

                try {
                    const newErrorText = JSON.parse(error.message).message;
                    if (newErrorText) errorText = newErrorText;
                    console.log("error.message.message", JSON.parse(error.message).message)
                } catch (e) {
                    console.log("get error message error", e)
                }
                dispatch(popupPush({
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    data: [errorText],
                    isDefaultError: true,
                    actionHandler: () => {
                    },
                    cancelVisible: false,
                }))
                dispatch(setIsPageLoading(undefined))
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function getPdf(): Thunk {
    return async (dispatch, getState) => {
        const differenceData = selectDifferenceData(getState());
        const docFilterList = selectDocFilterList(getState());
        const pairId = selectPairId(getState());
        dispatch(spinnerPlusStatus())
        await getMonitoringApiObj()
            .getPdf({
                pairId,
                onlyImportant: true,
                discrepancies: differenceData.filter((el) => {
                    const hide = el.discrepancyElements && el.discrepancyElements.length > 0 && el.discrepancyElements.filter(discrepancy => docFilterList.includes(discrepancy)).length === 0;
                    return !hide;
                }),
            })
            .then(res => res.blob())
            .then((res) => {
                const urlId = selectUrlId(getState())
                const blob = new Blob([res], {type: 'application/pdf'});

                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);

                // @ts-ignore
                link.href = url;
                link.download = `Сравнение документов ${urlId}`;

                document.body.appendChild(link);

                link.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.log("getPdf", error)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function getUnrecognizedPackagesImages(documentId: number, direction: Directions = Directions.Left): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus());
        await getMonitoringApiObj()
            .getUnrecognizedPackage(documentId)
            .then(res => res.json())
            .then(async (res) => {
                const unrecognizedPackage: INotRecognizedPackage[] = res;
                let images = [];
                let promiseArray = [];
                dispatch(spinnerPlusStatus());
                unrecognizedPackage.forEach((elem) => {
                    promiseArray.push(getUploadApiObj()
                        .getImageById(elem.jsonContent.pages.id)
                        .then(response => response.json())
                        .then(response => {
                            const imageBase64: IConvertedImage = response;
                            images.push(imageBase64.rawBase64);
                        })
                    )

                })
                await Promise.allSettled(promiseArray).then((results) => {
                    let errors = [];
                    results.filter(result => result.status === 'rejected').forEach((elem: PromiseRejectedResult) => {
                        results.filter(result => result.status === 'rejected').forEach((elem: PromiseRejectedResult) => {
                            let err = 'Произошла ошибка';
                            try {
                                let message = JSON.parse(elem.reason.message);
                                err = message.message
                            } catch {

                            }

                            errors.push(err)
                        })
                    })

                    errors.length > 0 && dispatch(popupPush({
                        actionTitle: "Хорошо",
                        actionVisible: true,
                        data: errors,
                        actionHandler: () => {
                        },
                        isDefaultError: true,
                        cancelVisible: false,
                    }))
                    dispatch({
                        type: SET_DOCUMENT_IMAGES_LEFT,
                        payload: images.map(elem => (
                            {
                                fileUrl: `data:image/jpg;base64,${elem}`
                            }))
                    })
                    dispatch(spinnerMinusStatus())
                })
            })
            .catch((error) => {
                console.log("getUnrecognizedPackagesImages", error)
                dispatch(popupPush({
                    actionTitle: "Хорошо",
                    actionVisible: true,
                    data: ["Произошла ошибка"],
                    isDefaultError: true,
                    actionHandler: () => {

                    },
                    cancelVisible: false,
                }))
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function deleteUnrecognizedPackagesImages(packageId: number): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerPlusStatus());
        await getMonitoringApiObj()
            .getUnrecognizedPackage(packageId)
            .then(res => res.json())
            .then(async (res) => {
                const unrecognizedPackage: INotRecognizedPackage[] = res;
                dispatch(spinnerPlusStatus());
                await getMonitoringApiObj()
                    .deleteUnrecognizedPackage(packageId, {
                        imgId: unrecognizedPackage.map((elem) => {
                            return elem.jsonContent.pages.id;
                        }),
                    })
                    .then(() => {
                    })
                    .finally(() => {
                        dispatch(spinnerMinusStatus())
                    })
            })
            .catch((error) => {
                console.log("deleteUnrecognizedPackagesImages", error)
            })
            .finally(() => {
                dispatch(spinnerMinusStatus())
            })
        dispatch(spinnerMinusStatus())
    }
}

export function onClearDifferencePage(): Thunk {
    return (dispatch, getState) => {
        dispatch({type: SET_DETAILED_DOCUMENT_ID_RIGHT, payload: undefined});
        dispatch({type: SET_DETAILED_DOCUMENT_ID_LEFT, payload: undefined});
        dispatch({type: SET_DETAILED_DOCUMENTS, payload: []});
        dispatch({type: SET_DOCUMENT_IMAGES_LEFT, payload: []});
        dispatch({type: SET_DOCUMENT_IMAGES_RIGHT, payload: []});
        dispatch({type: SET_DETAILED_DIFF, payload: undefined});
        dispatch({type: SET_DETAILED_PAIR, payload: undefined});
        dispatch({type: SET_DIFFERENCE_DATA, payload: []});
        dispatch({type: SET_COMMENT, payload: ''});
        dispatch({type: SET_IS_PAGE_LOADING, payload: undefined});
        customControllerUtils.controller.abort();
        customControllerUtils.setNewController();
    }
}

export function setCommentInputShown(flag: boolean): Thunk {
    return async dispatch => {
        dispatch({type: SET_COMMENT_INPUT_SHOWN, payload: flag});
    };
}

export function setDetailedDocumentLeftId(docId: string): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_DETAILED_DOCUMENT_ID_LEFT,
            payload: docId,
        });
    };
}

export function setLeftScale(leftScale: number): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_LEFT_SCALE,
            payload: leftScale,
        });
    };
}

export function setRightScale(rightScale: number): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_RIGHT_SCALE,
            payload: rightScale,
        });
    };
}

export function setIsTrackDisabled(flag: boolean): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_IS_TRACK_DISABLED,
            payload: flag,
        });
    };
}

export function setListPage(pageNumber: number): Thunk {
    return async dispatch => {
        setStoragePage(pageNumber);
        dispatch({type: SET_LIST_PAGE, payload: pageNumber});
    };
}

export function setDifferenceComment(detailedDiff: string, comment: string): Thunk {
    return async (dispatch, getState) => {
        const pair = selectDetailedPair(getState());
        const diffs = selectDifferenceData(getState());
        if (pair) {
            const findIndex = diffs.findIndex(el => el.indexFromApi === +detailedDiff);
            if (findIndex >= 0) {
                diffs[findIndex].comment = comment;
                dispatch({
                    type: SET_DIFFERENCE_DATA,
                    payload: [...diffs],
                })
                await getMonitoringApiObj()
                    .updateDocuments(pair.id, {...pair, comment: pair.comment ? pair.comment : "", discrepancies: diffs})
                    .then(async () => {
                    })
                    .catch((err) => {
                        try {
                            const error = JSON.parse(err);
                            dispatch(popupPush({
                                actionTitle: "Хорошо",
                                isDefaultError: true,
                                actionVisible: true,
                                data: [error.message ? error.message : "Произошла ошибка"],
                                actionHandler: () => {
                                },
                                cancelVisible: false,
                            }))
                        } catch (e) {
                            dispatch(popupPush({
                                actionTitle: "Хорошо",
                                actionVisible: true,
                                data: ["Произошла ошибка"],
                                isDefaultError: true,
                                actionHandler: () => {
                                },
                                cancelVisible: false,
                            }))
                        }
                    })
            }
        }
    }
}

export function monitoringReducer(
    state: IMonitoringState = initialState,
    action: MonitoringActions
): IMonitoringState {
    switch (action.type) {
        case SET_LOADS:
            return {
                ...state,
                list: action.payload,
            };
        case SET_DETAILED_DIFF:
            return {
                ...state,
                selectedDiff: action.payload,
            }
        case SET_COMMENT_INPUT_SHOWN:
            return {
                ...state,
                isCommentInputShown: action.payload,
            }
        case SET_DOCUMENT_IMAGES_RIGHT:
            return {
                ...state,
                rightDetailedImages: action.payload,
            }
        case SET_DOCUMENT_IMAGES_LEFT:
            return {
                ...state,
                leftDetailedImages: action.payload,
            }
        case SET_DETAILED_DOCUMENT_ID_LEFT:
            return {
                ...state,
                detailedDocumentIdLeft: action.payload,
            }
        case SET_DETAILED_DOCUMENT_ID_RIGHT:
            return {
                ...state,
                detailedDocumentIdRight: action.payload,
            }
        case SET_LEFT_SCALE:
            return {
                ...state,
                leftScale: action.payload,
            }
        case SET_RIGHT_SCALE:
            return {
                ...state,
                rightScale: action.payload,
            }
        case  SET_IS_TRACK_DISABLED:
            return {
                ...state,
                isTrackDisabled: action.payload,
            }
        case  SET_SEARCH_OPTIONS:
            return {
                ...state,
                searchOptions: action.payload,
            }
        case  SET_SORT_TYPE:
            return {
                ...state,
                sortType: action.payload,
            }
        case  SET_DIFFERENCE_DATA:
            return {
                ...state,
                differenceData: action.payload,
            }
        case SET_LIST_PAGE:
            return {...state, listPage: action.payload}
        case SET_PAIR_ID:
            return {...state, pairId: action.payload}
        case SET_MENU_OPEN:
            return {...state, menuOpen: action.payload}
        case SET_DIFFERENCE_BAR_POSITION:
            return {...state, panelPosition: action.payload}
        case SET_FIRST_DOC_POSITION:
            return {...state, docPosition: action.payload}
        case SET_SCROLL_SYNC:
            return {...state, scrollSync: action.payload}
        case SET_IS_CHANGING_DETAILED_DIFF:
            return {...state, isChangingDetailedDiff: action.payload}
        case SET_IS_CHANGING_VIEW:
            return {...state, isChangingView: action.payload}
        case SET_IS_PAGE_LOADING:
            return {...state, pageLoadingState: action.payload}
        case SET_MAGNIFIER_ACTIVE:
            return {...state, isMagnifierActive: action.payload}
        case SET_DETAILED_PAIR:
            return {...state, detailedPair: action.payload}
        case SET_ZOOM_COUNT:
            return {...state, zoomCount: action.payload}
        case UPDATE_DOC_FILTER_LIST:
            return {...state, docFilterList: action.payload}
        case SET_SELECTED_ROW_IDS:
            return {...state, selectedRowIds: action.payload}
        case SET_PAIR_COMMENT:
            return {...state, selectedRowIds: action.payload}
        case SET_DIFFERENCE_COMMENT:
            return {...state, differenceComment: action.payload}
        case SET_DIFFERENCE_LINK:
            return {...state, differenceLink: action.payload}
        case SET_ORDER_BY:
            return {...state, sortOrderBy: action.payload}
        default:
            return state;
    }
}

import React from "react";
import styles from "./footer.module.scss";
import moment from "moment";

interface IFooterItem {
    leftItemText: string | undefined;
    rightItemText?: string | undefined;
    isLink?: boolean;
    isPhoneNumber?: boolean;
    linkRef?: string;
    className?: string;
}

export const FOOTER_ELEMENTS: IFooterItem[] = [
    {
        leftItemText: "Telegram:",
        rightItemText: "@CORRECT_support_bot",
        isLink: true,
        linkRef: 'https://tlgg.ru/@CORRECT_support_bot',
    },
    {
        leftItemText: "Телефон:",
        rightItemText: "+ 7 (495) 740-12-12",
        isLink: true,
        linkRef: 'tel:+74957401212',
        className: 'RightLinkNoDecoration',
        isPhoneNumber: true,
    },
    {
        leftItemText: "E-mail:",
        rightItemText: "support@correct.su",
        isLink: true,
        linkRef: 'mailto:support@correct.su',
        className: 'rightPartRowRightLink',
    },
]

export function Footer() {
    return <div className={styles.footer}>
        <div className={styles.leftPart}>
            <div className={styles.rightPartRow}>
                <div className={styles.leftPartRowLeftItem}>
                    ООО «РозумСофт», {moment().year()}
                </div>
            </div>
        </div>
        <div className={styles.rightPart}>
            {FOOTER_ELEMENTS.map((elem, index) => {
                return <React.Fragment key={`right_${index}${index + 1}`}>
                    <div key={`right_within${index}${index}`} className={styles.rightPartRow}>
                        {elem.leftItemText && <div className={styles.rightPartRowLeftItem}>
                            {elem.leftItemText}
                        </div>}
                        <a className={`${styles.rightPartRowRightLink} ${elem.className && styles[elem.className]}`} target={!elem.isPhoneNumber ? "_blank" : undefined} href={elem.linkRef}>
                            {elem.rightItemText}
                        </a>
                    </div>
                    {(index + 1 !== FOOTER_ELEMENTS.length) && <div className={styles.divider}>
                    </div>}
                </React.Fragment>
            })}
        </div>
    </div>
}

import {css, styled} from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

export const StarIconBackground = styled(StarIcon)<{isDetailed?: boolean}>`
  position: absolute;
  ${({isDetailed}) => (isDetailed && css`
    color: red;
  `)}
  top: 2px;
  left: -1px;
  width: 18px;
  height: 18px;
  
`;
export const StarIconFront = styled(StarBorderIcon)<{isDetailed?: boolean}>`
  ${({isDetailed}) => (isDetailed && css`
    color: red;
  `)}
  position: absolute;
  top: 2px;
  left: 8px;
  width: 18px;
  height: 18px;
`;

import * as React from "react";
import {PopupElement} from "../../ducks/popup";
import styles from "./PopupDialog.module.scss";
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import {CommonErrorText} from "./CommonErrorPopup/common-error-text";
import {CommentInputIdPrefix} from "../DifferenceListSidebar/CommentInput";

export interface PopupDialogStateProps {
    popups: PopupElement[];
    reportText: string;
    comment: string;
}

export interface PopupDialogDispatchProps {
    onPopupDelete: (id: string) => void;
    updateTechReportText: (value: string) => void;
    setComment: (value: string) => void;
}

export type PopupDialogProps = PopupDialogStateProps & PopupDialogDispatchProps;

export class PopupDialog extends React.Component<PopupDialogProps> {
    state = {
        message: '',
        checked: false
    }

    public render() {
        const popups = this.props.popups;
        return popups.length ? (
            <div className={styles.popupBack}>
                {popups.map((popupElem, index) => {
                    const actionHandler = () => {
                        if (popupElem.actionHandler) popupElem.actionHandler();
                        this.props.onPopupDelete(popupElem.id!);
                    };

                    const cancelHandler = () => {
                        if (popupElem.cancelHandler) popupElem.cancelHandler();
                        this.props.onPopupDelete(popupElem.id!);
                    };

                    const finishHandler = () => {
                        if (popupElem.finishHandler) popupElem.finishHandler();
                        this.props.onPopupDelete(popupElem.id!);
                    };

                    const clearHandler = () => {
                        if (popupElem.clearHandler) popupElem.clearHandler();
                        this.props.setComment('')
                    };

                    const actionTitle = popupElem.actionTitle
                        ? popupElem.actionTitle
                        : 'Ок';
                    const cancelTitle = popupElem.cancelTitle
                        ? popupElem.cancelTitle
                        : "Отмена";
                    let actionButtonDisabled = popupElem.technicalSupport && (this.props.reportText.trim() === "" || !this.state.checked)


                    return (
                        <div id={`id_${popupElem.id}`} key={popupElem.id} className={styles.popupContainer}>
                            {popupElem.isDefaultError && <CommonErrorText/>}
                            {!popupElem.isDefaultError && <div className={styles.messageContainer}>
                                {!popupElem.isDefaultError && popupElem.data.map(splitElem => {
                                    return (
                                        <div key={Math.random()} className={styles.message}>
                                            {splitElem}
                                        </div>
                                    );
                                })}

                                {
                                    popupElem.technicalSupport && <TextField
                                        id={`${CommentInputIdPrefix}TechReport`}
                                        placeholder={'Сообщить об ошибке'}
                                        value={this.props.reportText}
                                        sx={{
                                            marginTop: "20px",
                                            backgroundColor: "#FFF",
                                            width: '425px',
                                        }}
                                        multiline
                                        minRows={2}
                                        maxRows={6}
                                        variant="outlined"
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            this.props.updateTechReportText(value ? value : '');
                                        }}
                                    />
                                }
                                {
                                    popupElem.comment &&
                                    <TextField
                                        id={`${CommentInputIdPrefix}CommentPopupDialog`}
                                        placeholder={'Текст комментария'}
                                        value={this.props.comment}
                                        sx={{
                                            marginTop: "20px",
                                            backgroundColor: "#FFF",
                                            width: '425px',
                                        }}
                                        multiline
                                        minRows={4}
                                        maxRows={6}
                                        variant="outlined"
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            this.props.setComment(value ? value : '');
                                        }}
                                    />
                                }
                            </div>}
                            {popupElem.flag && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{
                                                height: '32px',
                                                width: '32px',
                                                color: `#F57B20`,
                                                '&.Mui-checked': {
                                                    color: `#F57B20`,
                                                },
                                                fontSize: '8px',
                                            }}
                                            onChange={(e) =>
                                                this.setState(prev => ({
                                                    ...prev,
                                                    checked: e.target.checked
                                                }))
                                            }
                                        />}
                                    sx={{
                                        width: '425px',
                                        margin: "auto"
                                    }}
                                    label={<p style={{fontSize: '13px'}}>Даю согласие на передачу загруженных документов в службу технической поддержки для анализа обнаруженной ошибки</p>}
                                />
                            )}

                            <div className={styles.buttonContainer}>
                                {popupElem.actionVisible && !popupElem.comment && (<Button
                                    disabled={actionButtonDisabled}
                                    autoFocus={true}
                                    className={`${styles.buttonAction} ${actionButtonDisabled && styles.disabled}`}
                                    onClick={actionHandler}
                                    variant={'contained'}
                                >
                                    {actionTitle}
                                </Button>)}

                                {popupElem.cancelVisible && (
                                    <Button
                                        variant={'contained'}
                                        autoFocus={true}
                                        className={styles.buttonCancel}
                                        onClick={cancelHandler}
                                    >
                                        {cancelTitle}
                                    </Button>
                                )}

                                {popupElem.comment && (
                                    <>
                                        <Button
                                            variant={'contained'}
                                            autoFocus={true}
                                            className={styles.buttonCancel}
                                            onClick={clearHandler}
                                        >
                                            Очистить
                                        </Button>
                                        {popupElem.actionVisible && (<Button
                                            disabled={actionButtonDisabled}
                                            autoFocus={true}
                                            className={`${styles.buttonAction} ${actionButtonDisabled && styles.disabled}`}
                                            onClick={actionHandler}
                                            variant={'contained'}
                                        >
                                            {actionTitle}
                                        </Button>)}
                                        <Button
                                            disabled={actionButtonDisabled}
                                            autoFocus={true}
                                            className={`${styles.buttonActionLong} ${actionButtonDisabled && styles.disabled}`}
                                            onClick={finishHandler}
                                            variant={'contained'}
                                        >
                                            Сохранить и завершить
                                        </Button>
                                    </>
                                )}

                            </div>
                        </div>
                    );
                })}
            </div>
        ) : (
            <></>
        );
    }
}

import {FC, useLayoutEffect} from "react";
import {DifferencePageConnected} from "./DifferencePageConnected";
import {getStoreUtils} from "../../store/utils";
import {useSelector} from "react-redux";
import {selectUrlId} from "../../selectors/other";
import {getPublicDocs} from "../../ducks/montitoring";

export const DifferencePagePublic: FC = () => {
    const {dispatch} = getStoreUtils();
    const urlId = useSelector(selectUrlId);

    useLayoutEffect(() => {
        if (urlId) {
            dispatch(getPublicDocs(urlId as string));
        }
    }, [urlId]);

    return <>
        <DifferencePageConnected/>
    </>
}

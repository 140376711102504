import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#F57B20',
        }
        ,secondary: {
            main: '#F57B20',
        }
    },
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    width: '26px', // Установите желаемую ширину
                    height: '26px', // Установите желаемую высоту
                    '&.Mui-checked': {
                        // Установите стили для чекбокса, когда он отмечен
                    },
                },
            },
        },
    },
});

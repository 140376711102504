import React, {FC, ReactNode} from "react";
import {withStyles} from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu, {MenuProps} from "@mui/material/Menu";
import StarIcon from "@mui/icons-material/Star";
import styles from "../../pages/DifferencePage/DifferencePage.module.scss";
import StarBorderIcon from "@mui/icons-material/StarBorder";

type Props = {
    isImportant: boolean;
    setCommentHandler: (event: React.MouseEvent) => void;
    setImportantHandler: (event: React.MouseEvent) => void;
    commentInput?: ReactNode;
} & MenuProps;


const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontSize: '14px !important',
        fontWeight: 500,
        backgroundColor: 'transparent !important',
        transition: 'none',
        display: 'flex',
        gap: '4px',
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },

}))(MenuItem);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #000000',
        boxShadow: "none",
        //position: 'static'
    },
    muiList: {
        padding: 0,
    }
})(Menu);

export const DifferenceContextMenu: FC<Props> = (
    {
        isImportant,
        setCommentHandler,
        setImportantHandler,
        commentInput,
        ...props
    }) => {
    return <StyledMenu
        {...props}
    >
        <StyledMenuItem onClick={setImportantHandler}>
            <span>{isImportant ? 'Отметить как не важное' : 'Отметить как важное'}</span>
        </StyledMenuItem>
        {commentInput && <StyledMenuItem disableRipple sx={{width: '300px', overflow: 'auto'}}>
            {commentInput}
        </StyledMenuItem>}
    </StyledMenu>
}

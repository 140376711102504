interface IRoutes {
    Home: string;
    Upload: string;
    Auth: string;
    Authorization: string;
    Monitoring: string;
    Doc: string;
    DocPublic: string;
    Docs: string;
    Info: string;
}

export const ROUTES: IRoutes = {
    Home: "/",
    Auth: "/auth",
    Authorization: "/authorization",
    Monitoring: "/list",
    Upload: "/uploading",
    Doc: "/docs",
    DocPublic: "/docs/public",
    Docs: "/docs",
    Info: "/info",
}

import {createTheme} from "@mui/material/styles";

export const differenceSideBarTheme = (color) => {
    // @ts-ignore
    return createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    popper: {
                        marginLeft: '10px',
                        left: '20px',
                    },
                    tooltip: {
                        color: 'rgba(0, 0, 0, 0.87)',
                        backgroundColor: `${color} !important`,
                        borderColor: '#000',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        marginLeft: '10px',
                        left: '20px',
                        ":root": {
                            backgroundColor: `${color} !important`,
                        },
                    },
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        fontFamily: 'Lato, SansSerif, sans-serif',
                        lineHeight: '14px'
                    }
                },
            },
        }
    })

}

import {FC, useLayoutEffect} from "react";
import {DifferencePageConnected} from "./DifferencePageConnected";
import {getStoreUtils} from "../../store/utils";
import {useSelector} from "react-redux";
import {selectUrlId} from "../../selectors/other";
import {getDocIdByKey} from "../../ducks/montitoring";

export const DifferencePagePrivate: FC = () => {
    const {dispatch} = getStoreUtils();
    const urlId = useSelector(selectUrlId);

    useLayoutEffect(() => {
        if (urlId) {
            dispatch(getDocIdByKey(urlId as string));
        }
    }, [urlId]);

  return <>
      <DifferencePageConnected/>
  </>
}
